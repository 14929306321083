import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { StyleObj } from '../../../@types';
import { BettingSlipFrom, CashoutReport } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { createColumn } from '../../../utils';
import { getData } from '../../../utils/api';
import DateRangeSelect, { DayjsTimestamp } from '../../molecules/DateRangeSelect';
import TableTemplate from '../../templates/TableTemplate';
import { createNumberColumn } from '../../../helpers/columns';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
};

type CashoutReportQueryParams = {
  fromTimestamp: Dayjs;
  toTimestamp: Dayjs;
  from?: BettingSlipFrom;
  pendingSelections?: number;
  playerIds?: string[];
  cashoutType?: string;
  device?: string;
};

const columns: GridColDef[] = [
  createNumberColumn('coStake', 'CO Stake'),
  createNumberColumn('coPayout', 'CO Payout'),
  createNumberColumn('coProfit', 'CO Profit'),
  createColumn('coPercentage', 'CO %'),
  createNumberColumn('ifWinnings', 'If Winnings'),
  createNumberColumn('ifProfit', 'If Profit'),
  createColumn('ifPercentage', 'If %'),
  createNumberColumn('coIfProfit', 'CO/If Profit'),
  createColumn('coIfRatio', 'CO/If Ratio'),
  createColumn('coTickets', 'CO Eligible Tickets'),
];

const CashoutReportsPage = () => {
  const [queryParams, setQueryParams] = useState<CashoutReportQueryParams>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const { data, isFetching, isLoading } = useQuery([QUERY_KEYS.cashout, queryParams], {
    queryFn: (): Promise<CashoutReport> =>
      getData('cashout-report', {
        ...queryParams,
        fromTimestamp: queryParams.fromTimestamp.valueOf(),
        toTimestamp: queryParams.toTimestamp.valueOf(),
      }),
  });

  const handleDateChange = (newDate: DayjsTimestamp) => {
    updateQueryParams(newDate);
  };

  const updateQueryParams = (newParams: Partial<CashoutReportQueryParams>) => {
    setQueryParams((prevValue) => ({ ...prevValue, ...newParams }));
  };

  return (
    <Box sx={styles.container}>
      <DateRangeSelect
        date={{
          fromTimestamp: queryParams.fromTimestamp,
          toTimestamp: queryParams.toTimestamp,
        }}
        onDateChange={handleDateChange}
      />
      <TableTemplate
        experimentalFeatures={{ columnGrouping: true }}
        rows={[data] || []}
        getRowId={() => 'cashout-report'}
        columns={columns}
        loading={isFetching || isLoading}
        changeQuery={updateQueryParams}
        rowCount={1}
        showExport
      />
    </Box>
  );
};

export default CashoutReportsPage;
