import React, { useEffect, useState } from 'react';
import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { Box } from '@mui/material';
import TableTemplate from '../../templates/TableTemplate';
import { StyleObj } from '../../../@types';
import { generateDateRangeTimestamps, getTotalsRowClass } from '../../../helpers';
import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import DateRangeSelect, { DayjsTimestamp } from '../../molecules/DateRangeSelect';
import dayjs from 'dayjs';
import { createColumn } from '../../../utils';
import { ReportsTotals } from '../../../@types/api';
import { createNumberColumn, createProfitMarginColumn } from '../../../helpers/columns';
import usePermissions from '../../../hooks/usePermissions';
import { USER_ROLES } from '../../../constants/keycloak';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
  table: {
    border: 'none',
    '& .MuiDataGrid-columnHeader': {
      border: '1px solid rgba(0, 83, 55, 0.20)',
      backgroundColor: 'background.lightGreen',
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-of-type': {
        borderRadius: 0,
        width: '100% !important',
        maxWidth: 'unset !important',
      },
      '& .MuiDataGrid-sortIcon': {
        color: 'grey.700',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      color: 'grey.700',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .customGroupHeader': {
      backgroundColor: 'primary.main',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        color: 'white',
      },
    },
    '& .MuiDataGrid-columnHeader--emptyGroup': {
      backgroundColor: 'primary.main',
      '&:first-of-type': {
        borderTopLeftRadius: 8,
      },
      '&:last-of-type': {
        borderTopRightRadius: 8,
      },
    },
  },
};

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Tickets',
    description: 'Tickets',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'mainTicketCount' }, { field: 'bonusTicketCount' }, { field: 'totalTicketCount' }],
  },
  {
    groupId: 'Stakes',
    description: 'Stakes',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'mainStakeAmount' }, { field: 'bonusStakeAmount' }, { field: 'totalStakeAmount' }],
  },
  {
    groupId: 'Winnings',
    description: 'Winnings',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'mainWinningAmount' }, { field: 'bonusWinningAmount' }, { field: 'totalWinningAmount' }],
  },
  {
    groupId: 'Profit',
    description: 'Profit',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'mainProfitAmount' }, { field: 'bonusProfitAmount' }, { field: 'totalProfitAmount' }],
  },
];

const columns: GridColDef[] = [
  createColumn('fullName', 'Player'),
  createColumn('mainTicketCount', 'Main'),
  createColumn('bonusTicketCount', 'Credit'),
  createColumn('totalTicketCount', 'Total'),
  createNumberColumn('mainStakeAmount', 'Main'),
  createNumberColumn('bonusStakeAmount', 'Credit'),
  createNumberColumn('totalStakeAmount', 'Total'),
  createNumberColumn('mainWinningAmount', 'Main'),
  createNumberColumn('bonusWinningAmount', 'Credit'),
  createNumberColumn('totalWinningAmount', 'Total'),
  createNumberColumn('mainProfitAmount', 'Main'),
  createNumberColumn('bonusProfitAmount', 'Credit'),
  createNumberColumn('totalProfitAmount', 'Total'),
  createProfitMarginColumn(),
];

const generateTotalsRow = (data?: ReportsTotals) => {
  if (data) {
    return {
      ...data,
      userId: 'totals',
      fullName: 'Totals',
    };
  }
  return { userId: 'totals' };
};

const PlayersReportPage = () => {
  const { fromTimestamp, toTimestamp } = generateDateRangeTimestamps('today');
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });
  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination<ReportsTotals>(
    'reports/players',
    {
      page: 1,
      limit: 25,
      fromTimestamp: fromTimestamp.valueOf(),
      toTimestamp: toTimestamp.valueOf(),
    }
  );

  const { role } = usePermissions();

  const totalsRow = generateTotalsRow(data?.totals);

  const aggregatedData = data?.items ? [...data.items, totalsRow] : [];

  const { handleSort } = useSort(changeQuery);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  return (
    <Box sx={styles.container}>
      <DateRangeSelect date={date} onDateChange={handleDateChange} />
      <TableTemplate
        getRowId={(params) => params.userId}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        rows={aggregatedData}
        columns={columns}
        sx={styles.table}
        loading={isFetching || isLoading}
        rowCount={data?.count || 0}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        getRowClassName={getTotalsRowClass}
        changeQuery={changeQuery}
        showExport
        showFilter={role !== USER_ROLES.AGENT}
      />
    </Box>
  );
};

export default PlayersReportPage;
