import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import type { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import type { Tournament, TournamentList } from '../../@types/api';
import { FEATURED_WARNING_MESSAGE, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import {
  createCashOutColumn,
  createEditColumn,
  createIsActiveColumn,
  createSportIconColumn,
} from '../../helpers/columns';
import { defaultColumnsTournaments } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import Switch from '../atoms/Switch';
import TableIconContainer from '../atoms/TableIconContainer';
import FormModalLayout from '../layouts/FormModalLayout';
import TableTemplate from '../templates/TableTemplate';
import usePermissions from '../../hooks/usePermissions';

const TournamentsPage = () => {
  const {
    data: tournamentsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<TournamentList>('tournaments');
  const { deleteData, updateData } = useMutateData('tournaments', [QUERY_KEYS.tournaments]);
  const { handleSort } = useSort(changeQuery);
  const { openModal, closeModal } = useModal();
  const { hasPermission } = usePermissions();
  const showCashout = hasPermission('manageCashOut');

  const columns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('sportName', 'Sport', {
      valueGetter: (params: GridValueGetterParams<Tournament>) => params.row.sport.name,
    }),
    createSportIconColumn<Tournament>(),
    createColumn('competitionName', 'Competition', {
      valueGetter: (params: GridValueGetterParams<Tournament>) => params.row.competition.name,
    }),
    createColumn('name', 'Tournament Name'),
    createColumn('shortName', 'Tournament Short Name'),
    createColumn('position', 'Position'),
    createIsActiveColumn<Tournament>({
      updateData,
    }),

    createColumn('topLeague', 'Top League', {
      renderCell: (params) => {
        return (
          <Switch
            value={params.value}
            onChange={(e) => {
              updateData(params.row.id, { topLeague: e.target.checked });
            }}
            name='topLeague'
          />
        );
      },
    }),
    createColumn('topOffer', 'Top Offer', {
      renderCell: (params) => {
        return (
          <Switch
            value={params.value}
            onChange={(e) => {
              updateData(params.row.id, { topOffer: e.target.checked });
            }}
            name='topOffer'
          />
        );
      },
    }),
    createColumn('topOfferPosition', 'Top Offer Position'),
    createColumn('featured', 'Featured Tournament', {
      renderCell: (params) => {
        return (
          <Switch
            value={params.value}
            onChange={() => {
              openModal(
                <FormModalLayout
                  label={FEATURED_WARNING_MESSAGE}
                  onSave={() => {
                    updateData(params.row.id, { featured: !params.value });
                    closeModal();
                  }}
                  onClose={closeModal}
                />
              );
            }}
            name='featured'
          />
        );
      },
    }),
    createColumn('information', 'Information', {
      sortable: false,
      renderCell: (params) =>
        params.row.information && (
          <TableIconContainer>
            <Tooltip title={params.row.information}>
              <InfoIcon color='primary' />
            </Tooltip>
          </TableIconContainer>
        ),
    }),
    createEditColumn<Tournament>({
      deleteData,
    }),
  ];
  showCashout && columns.splice(-1, 0, createCashOutColumn<Tournament>({ updateData }));

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  return (
    <TableTemplate
      rows={tournamentsData?.items || []}
      columns={columns}
      rowCount={tournamentsData?.count ?? 0}
      loading={isFetching || isLoading}
      defaultVisibleColumns={defaultColumnsTournaments}
      createItemBtnLabel='Add new tournament'
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      formType='tournaments'
      changeQuery={changeQuery}
      showExport
    />
  );
};

export default TournamentsPage;
