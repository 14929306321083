import { EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import {
  GridCellParams,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
  MuiEvent,
  type GridColDef,
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { StyleObj } from '../../@types';
import { Event, EventWithResults } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { getLatestValue } from '../../helpers';
import { defaultColumnsResultsAndResolve } from '../../helpers/table';
import useContextMenu from '../../hooks/useContextMenu';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import IconRenderer from '../atoms/IconRenderer';
import TableIconContainer from '../atoms/TableIconContainer';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import ResolveModelIcon from '../icons/ResolveModelIcon';
import FormModal from '../modals/FormModal';
import ManageEventResultMenu, {
  HandleMenuItemClickField,
  HandleMenuItemClickOption,
} from '../molecules/ManageEventResultMenu';
import ResolveTable from '../organisms/ResolveTable';
import TableTemplate from '../templates/TableTemplate';

const styles: StyleObj = {
  container: {
    display: 'flex',
    height: '100%',
    minHeight: 0,
    gap: '1.5rem',
    position: 'relative',
    width: '100%',
  },
  columnWithIconWrapper: { display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center' },
  nameWrapper: { overflow: 'hidden', textOverflow: 'ellipsis' },
  resultWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
};

const ResultsAndResolvePage = () => {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const invalidateQuery = useInvalidateQuery();
  const { contextMenu, showContextMenu, hideContextMenu } = useContextMenu();

  const {
    data: eventsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<EventWithResults>('events/results', {
    page: 1,
    limit: 25,
    fromTimestamp: dayjs().startOf('day').valueOf(),
    toTimestamp: dayjs().endOf('day').valueOf(),
  });

  const { updateData } = useMutateData('events', [QUERY_KEYS.results]);
  const { createData: createOutcomeChangeVoid } = useMutateData('outcome-changes/void', [QUERY_KEYS.results]);
  const { createData: createOutcomeChangeDelete } = useMutateData('outcome-changes/delete-results', [
    QUERY_KEYS.results,
  ]);

  const { handleSort } = useSort(changeQuery);
  const { openModal } = useModal();

  // on eventsData change, check if selectedRow is still in the list
  // if not, hide the resolve table
  useEffect(() => {
    if (!selectedRow) return;

    const event = eventsData?.items.find((event) => event.id === selectedRow);
    if (!event) {
      setSelectedRow(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData]);

  const openEditDialog = (row: Event) => {
    openModal(<FormModal form='results' item={row} />);
  };

  const columns: GridColDef[] = [
    createColumn('sportIcon', 'Icon', {
      sortable: false,
      renderCell: (params) => (
        <TableIconContainer>
          <IconRenderer name={params.row.tournament.sport.icon} type='sport' />
        </TableIconContainer>
      ),
    }),
    createColumn('sportName', 'Sport', {
      valueGetter: (params: GridValueGetterParams<EventWithResults>) => params.row.tournament.sport.name,
    }),
    createColumn('competitionName', 'Competition', {
      valueGetter: (params: GridValueGetterParams<EventWithResults>) => params.row.tournament.competition.name,
    }),
    createColumn('tournamentName', 'Tournament', {
      align: 'left',
      renderCell: (params: GridRenderCellParams<EventWithResults>) => {
        return (
          <Box sx={styles.columnWithIconWrapper}>
            <ResolveModelIcon updateType={params.row.tournament.updateType} />
            <Box sx={styles.nameWrapper}>{params.row.tournament.name}</Box>
          </Box>
        );
      },
    }),
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('name', 'Event', {
      align: 'left',
      flex: 2,
      renderCell: (params: GridRenderCellParams<EventWithResults>) => {
        return (
          <Box sx={styles.columnWithIconWrapper}>
            <PublishStatusIcon
              status={params.row.transferStatus}
              viewBox='2 2 20 20'
              width='20'
              height='20'
              changed={params.row.changedTransferStatus}
            />
            <Box title={params.row.name} sx={styles.nameWrapper}>
              {getLatestValue(params.row, 'name')}
            </Box>
          </Box>
        );
      },
    }),
    createColumn('startDate', 'Starting time', {
      valueGetter: (params: GridValueGetterParams<EventWithResults>) =>
        dayjs(getLatestValue(params.row, 'startDate')).format('DD-MM-YYYY HH:mm'),
    }),
    createColumn('result', 'Result', {
      sortable: false,
      width: 96,
      renderCell: (params: GridRenderCellParams<EventWithResults>) => (
        <Box sx={styles.resultWrapper}>
          <Typography textAlign='center'>{params.row.mainEventPartScore?.valueHome}</Typography>
          <Typography textAlign='center'>{params.row.mainEventPartScore?.valueAway}</Typography>
        </Box>
      ),
    }),
    createColumn('edit', 'Edit', {
      sortable: false,
      renderCell: (params) => (
        <IconButton onClick={() => openEditDialog(params.row)} color='primary'>
          <EditOutlined />
        </IconButton>
      ),
    }),
  ];

  const hideResolveTable = () => {
    setSelectedRow(null);
  };

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const onCellClick = (params: GridCellParams, _event: MuiEvent<React.MouseEvent>) => {
    if (params.field !== 'edit' && params.field !== 'isActive' && params.field !== '__check__') {
      setSelectedRow(params.row.id);
      setSelectedRows([params.row.id]);
    }
  };

  const onRowSelectionModelChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection as string[]);
  };

  const onOutcomeChangeSuccess = (id: string) => {
    invalidateQuery([QUERY_KEYS.markets, { eventId: id }]);
  };

  const handleMenuItemClick = (option: HandleMenuItemClickOption, field: HandleMenuItemClickField) => {
    if (field === 'transferStatus') {
      updateData(
        'bulk',
        {
          eventIds: selectedRows,
          [field]: option.id,
        },
        () => {
          onOutcomeChangeSuccess(selectedRow as string);
        }
      );
    } else if (field === 'void') {
      createOutcomeChangeVoid(
        {
          eventIds: selectedRows,
          [field]: option.id,
        },
        () => {
          onOutcomeChangeSuccess(selectedRow as string);
        },
        'Items updated successfully.'
      );
    } else {
      createOutcomeChangeDelete(
        {
          eventIds: selectedRows,
          [field]: option.id,
        },
        () => {
          onOutcomeChangeSuccess(selectedRow as string);
        },
        'Items updated successfully.'
      );
    }

    hideContextMenu();
  };

  const slotProps = {
    row: {
      onContextMenu: selectedRows.length > 0 ? showContextMenu : undefined,
      style: { cursor: selectedRows.length > 0 ? 'context-menu' : 'pointer' },
    },
  };

  return (
    <>
      <Box sx={styles.container}>
        <TableTemplate
          rows={eventsData?.items || []}
          columns={columns}
          rowCount={eventsData?.count || 0}
          loading={isFetching || isLoading}
          defaultVisibleColumns={defaultColumnsResultsAndResolve}
          handlePaginationModelChange={updateQueryParams}
          handleSort={handleSort}
          handleSearch={handleSearch}
          checkboxSelection
          onCellClick={onCellClick}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={selectedRows}
          slotProps={slotProps}
          changeQuery={changeQuery}
        />
        {selectedRow && (
          <ResolveTable
            eventId={selectedRow}
            tournament={eventsData?.items.find((event) => event.id === selectedRow)?.tournament}
            handleClose={hideResolveTable}
          />
        )}
      </Box>
      <ManageEventResultMenu
        contextMenu={contextMenu}
        handleClose={hideContextMenu}
        handleItemClick={handleMenuItemClick}
      />
    </>
  );
};

export default ResultsAndResolvePage;
