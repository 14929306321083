import { Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BETSLIP_CONTROL_DELAY_OPTIONS, PRODUCT_TYPES_OPTIONS, QUERY_KEYS } from '../../constants';
import useMutateData from '../../hooks/useMutateData';
import FormFieldStack from '../atoms/FormFieldStack';
import Switch from '../atoms/Switch';
import FormSelect from '../molecules/FormSelect';
import { useQuery } from '@tanstack/react-query';
import { COLUMN_FIELDS } from '../../constants/table';
import { getData } from '../../utils/api';
import { UserSettings } from '../../@types/api';

const defaultValues = {
  rejectAll: undefined,
  verifyAll: undefined,
  timeDelaySeconds: undefined,
  eligibleForCashout: undefined,
};

type FilterSportsLimitsData = {
  rejectAll: boolean;
  verifyAll: boolean;
  timeDelaySeconds?: number;
  eligibleForCashout: boolean;
};

type BetslipControlTabProps = {
  productValue: string;
  id: string;
  rejectAll?: boolean;
  verifyAll?: boolean;
  timeDelaySeconds?: number;
  handleClose: () => void;
};

const BetslipControlTab = ({
  id,
  handleClose,
  productValue,
  rejectAll,
  verifyAll,
  timeDelaySeconds,
}: BetslipControlTabProps) => {
  const { control, handleSubmit, reset, register } = useForm<FilterSportsLimitsData>({
    defaultValues,
  });

  const { updateData } = useMutateData(`risk-management/entity-risk-factors/${productValue}/punter`, [
    QUERY_KEYS.riskPlayers,
    id,
  ]);

  const { updateData: updateUserSettings } = useMutateData('user-settings', [QUERY_KEYS.userSettings]);

  const { data: userSettings } = useQuery([QUERY_KEYS.userSettings, id], {
    queryFn: (): Promise<UserSettings> => getData(`user-settings/${id}`),
  });

  const handleSave = (data: FilterSportsLimitsData) => {
    const { eligibleForCashout, ...limitData } = data;
    updateUserSettings(id, {eligibleForCashout}, () => handleClose());
    updateData(id, limitData, () => handleClose());
  };

  useEffect(() => {
    reset({
      ...defaultValues,
      rejectAll: rejectAll,
      verifyAll: verifyAll,
      timeDelaySeconds: timeDelaySeconds,
      eligibleForCashout: userSettings?.[COLUMN_FIELDS.CASHOUT],
    });
  }, [reset, rejectAll, verifyAll, timeDelaySeconds, userSettings]);

  return (
    <Stack spacing={2}>
      <Typography variant='h5'>{PRODUCT_TYPES_OPTIONS.find((item) => item.id === productValue)?.name}</Typography>
      <Stack spacing={2} mt={2}>
        <FormSelect
          label='Time delay'
          control={control}
          name='timeDelaySeconds'
          options={BETSLIP_CONTROL_DELAY_OPTIONS}
        />
        <FormFieldStack label='Reject all'>
          <Controller
            {...register('rejectAll')}
            control={control}
            render={({ field }) => <Switch {...field} ref={null} />}
          />
        </FormFieldStack>
        <FormFieldStack label='Verify all'>
          <Controller
            {...register('verifyAll')}
            control={control}
            render={({ field }) => <Switch {...field} ref={null} />}
          />
        </FormFieldStack>
        <FormFieldStack label='Cash Out'>
          <Controller
            control={control}
            name='eligibleForCashout'
            render={({ field }) => <Switch {...field} ref={null} />}
          />
        </FormFieldStack>
      </Stack>
      <Stack spacing={2} direction='row' justifyContent='flex-end' mt={3}>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' type='submit' onClick={handleSubmit((data) => handleSave(data))}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default BetslipControlTab;
