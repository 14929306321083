import { Box, Button, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { PRODUCT_TYPES_OPTIONS, QUERY_KEYS } from '../../constants';
import useMutateData from '../../hooks/useMutateData';
import { PaginatedData, RiskFactor, Sport } from '../../@types/api';
import { useQuery } from '@tanstack/react-query';
import { getData } from '../../utils/api';

type SportsLimitsTabProps = {
  id: string;
  productValue: string;
  handleClose: () => void;
};

const styles = {
  dropdownsWrapper: { display: 'flex', gap: '0 20px', marginBottom: '20px' },
  container: { maxHeight: '400px', overflow: 'auto' },
  menuProps: { maxHeight: 360 },
};

const SportsLimitsTab = ({ handleClose, productValue, id }: SportsLimitsTabProps) => {
  const { updateData } = useMutateData(`risk-management/entity-risk-factors/${productValue}/punter`, [
    QUERY_KEYS.riskPlayers,
    id,
  ]);
  const [selectPairs, setSelectPairs] = useState([{ key: '', value: '' }]);

  const handleValueChange = (index: number, key: string, value: unknown) => {
    const newPairs = [...selectPairs];
    newPairs[index] = { ...newPairs[index], [key]: value };
    setSelectPairs(newPairs);
  };

  const handleAddPair = () => {
    setSelectPairs([...selectPairs, { key: '', value: '' }]);
  };

  const handleSubmit = () => {
    const data = selectPairs.reduce<Record<string, string>>((acc, curr) => {
      if (curr.key) {
        acc[curr.key] = curr.value;
      }
      return acc;
    }, {});
    updateData(id, data, handleClose);
  };

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  const { data: riskFactorData } = useQuery([QUERY_KEYS.riskFactors], {
    queryFn: (): Promise<PaginatedData<RiskFactor>> => getData('risk-management/risk-factors'),
  });

  return (
    <Stack spacing={2}>
      <Typography variant='h5'>{PRODUCT_TYPES_OPTIONS.find((item) => item.id === productValue)?.name}</Typography>
      <Box sx={styles.container}>
        {selectPairs.map((pair, index) => (
          <Box key={index} sx={styles.dropdownsWrapper}>
            <Select
              value={pair.key}
              onChange={(e) => handleValueChange(index, 'key', e.target.value)}
              MenuProps={{ sx: styles.menuProps }}
              sx={{ width: 150 }}
            >
              {sportsData?.items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={pair.value}
              onChange={(e) => handleValueChange(index, 'value', e.target.value)}
              MenuProps={{ sx: styles.menuProps }}
              sx={{ width: 150 }}
            >
              {riskFactorData?.items.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ))}
      </Box>
      <Stack direction='row' justifyContent='flex-end' spacing={2} mt={3}>
        <Button variant='contained' color='info' onClick={handleAddPair}>
          Add new limit
        </Button>
        <Button variant='outlined' onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' type='submit' onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default SportsLimitsTab;
