import { usePagination } from '../../../hooks/usePagination';
import useSort from '../../../hooks/useSort';
import { Box } from '@mui/material';
import TableTemplate from '../../templates/TableTemplate';
import { StyleObj } from '../../../@types';
import { getTotalsRowClass } from '../../../helpers';
import { GridColDef } from '@mui/x-data-grid';
import { createColumn } from '../../../utils';
import { JackpotReport } from '../../../@types/api';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import DateRangeSelect, { DayjsTimestamp } from '../../molecules/DateRangeSelect';
import { CURRENCY } from '../../../constants';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
};

const JackpotReportsPage = () => {
  const [date, setDate] = useState<{ fromTimestamp: Dayjs; toTimestamp: Dayjs }>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination<JackpotReport>(
    'reports/jackpots',
    {
      page: 1,
      limit: 25,
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    }
  );

  const generateTotalsRow = (data?: JackpotReport) => {
    if (data) {
      return {
        id: 'totals',
        winnerName: data.winnersCount,
        amount: data.amount,
      };
    }
    return { id: 'totals' };
  };

  const totalsRow = generateTotalsRow(data?.totals);

  const aggregatedData = data?.items ? [...data.items, totalsRow] : [];

  const { handleSort } = useSort(changeQuery);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  const formatDate = (date: string | number | Date | Dayjs | null | undefined) => {
    return dayjs(date).format('DD-MM-YYYY HH:mm:ss');
  };

  const columns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
      renderCell: (params) => (params.row.id === 'totals' ? 'Totals' : params.value),
    }),
    createColumn('type', 'Type', {
      sortable: true,
      valueGetter: (params) => params.row.type,
    }),
    createColumn('name', 'Name', { sortable: true }),

    createColumn('wonAt', 'Date and Time', {
      sortable: true,
      renderCell: (params) => (params.row.id === 'totals' ? '' : formatDate(params.value)),
    }),
    createColumn('winnerName', 'Winner', { sortable: true }),

    createColumn('amount', 'Winning Amount', {
      sortable: true,
      renderCell: (params) => `${params.row.amount} ${CURRENCY.symbol} `,
    }),
  ];

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  return (
    <Box sx={styles.container}>
      <DateRangeSelect date={date} onDateChange={handleDateChange} />
      <TableTemplate
        experimentalFeatures={{ columnGrouping: true }}
        rows={aggregatedData}
        columns={columns}
        loading={isFetching || isLoading}
        rowCount={data?.count || 0}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        getRowClassName={getTotalsRowClass}
        changeQuery={changeQuery}
        showExport
      />
    </Box>
  );
};

export default JackpotReportsPage;
