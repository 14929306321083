import { useAuth } from 'neofusion-fe-shared';
import { Navigate, Outlet } from 'react-router-dom';
import { Permission } from '../../@types';
import usePermissions from '../../hooks/usePermissions';

type Props = {
  permission: Permission;
};

const ProtectedPage = ({ permission }: Props) => {
  const { userId } = useAuth();
  const { hasPermission } = usePermissions();

  if (!userId || !hasPermission(permission)) {
    return <Navigate to='/' />;
  }

  return <Outlet />;
};

export default ProtectedPage;
