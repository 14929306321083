import { Control, FieldValues, Path } from 'react-hook-form';
import { QUERY_KEYS } from '../../constants';
import { Customer } from '../../@types/api';
import FormAutocomplete, { AutocompleteItem, FormAutocompleteProps } from '../organisms/FormAutocomplete';

const defaultGetOptionLabel = (options: Customer[], value: string) => {
  const option = options.find((option) => option.userId === value);
  return option ? `${option.firstName} ${option.lastName} (${option.username})` : '';
};

type Props<O extends AutocompleteItem, T extends FieldValues> = Partial<FormAutocompleteProps<O, T>> & {
  control: Control<T>;
  name: Path<T>;
};

const CustomerSelect = <T extends FieldValues>({
  control,
  name,
  label = 'Players',
  queryKey = [QUERY_KEYS.customers],
  url = 'customer/search',
  getOptionLabel = defaultGetOptionLabel,
  ...rest
}: Props<Customer, T>) => {
  return (
    <FormAutocomplete<Customer, T>
      name={name}
      control={control}
      label={label}
      getOptionLabel={getOptionLabel}
      url={url}
      queryKey={queryKey}
      apiService='crm'
      optionsFilter='userId'
      multiple
      {...rest}
    />
  );
};

export default CustomerSelect;
