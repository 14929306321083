import type { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { MarketType, MarketTypeList } from '../../@types/api';
import { MARKET_TYPE_USED_FOR_OPTIONS, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import {
  createCashOutColumn,
  createDescriptionColumn,
  createEditColumn,
  createIsActiveColumn,
  createSportIconColumn,
} from '../../helpers/columns';
import { defaultColumnsMarkets } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import FormModal from '../modals/FormModal';
import TableTemplate from '../templates/TableTemplate';
import { capitalize } from '@mui/material';
import { getMarketTypeName } from '../../helpers';

const MarketTypesPage = () => {
  const {
    data: marketTypesData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<MarketTypeList>('market-types');
  const { deleteData, updateData } = useMutateData('market-types', [QUERY_KEYS.marketTypes]);
  const { handleSort } = useSort(changeQuery);

  const { openModal } = useModal();
  const navigate = useNavigate();

  const addOutcome = (marketId: string, sportId: string) => {
    navigate(`../outcomes`, {
      state: {
        defaultValues: {
          marketTypeId: marketId,
        },
        defaultFilters: {
          isActive: true,
          language: 'en',
          sportIds: [sportId],
          marketTypeIds: [marketId],
        },
      },
    });
    openModal(<FormModal form='outcomes' />);
  };

  const columns: GridColDef[] = [
    createColumn('id', 'ID', { sortable: false }),
    createColumn('sportName', 'Sport', {
      valueGetter: (params) => params.row.sport.name,
    }),
    createSportIconColumn<MarketType>(),
    createColumn('name', 'Market Name', {
      valueGetter: (params: GridValueGetterParams<MarketType>) => getMarketTypeName(params.row, false),
    }),
    createColumn('shortName', 'Market Short Name'),
    createColumn('usedFor', 'Used For', {
      valueGetter: (params: GridValueGetterParams<MarketType>) => {
        const usedFor = params.row.usedFor;
        return usedFor
          .map((usedFor) => MARKET_TYPE_USED_FOR_OPTIONS.find((option) => option.id === usedFor)?.name)
          .join(', ');
      },
      sortable: false,
    }),
    createColumn('type', 'Type', {
      valueGetter: (params: GridValueGetterParams<MarketType>) => capitalize(params.row.type.split('_').join(' ')),
    }),
    createColumn('position', 'Position'),
    createDescriptionColumn(),
    createIsActiveColumn<MarketType>({
      updateData,
    }),
    createCashOutColumn<MarketType>({ updateData }),
    createEditColumn({
      deleteData,
      addOutcome,
    }),
  ];

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  return (
    <TableTemplate
      rows={marketTypesData?.items || []}
      columns={columns}
      loading={isFetching || isLoading}
      rowCount={marketTypesData?.count || 0}
      createItemBtnLabel='Add new market'
      defaultVisibleColumns={defaultColumnsMarkets}
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
      formType='markets'
      showExport
    />
  );
};

export default MarketTypesPage;
