import { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { GetApp, PictureAsPdf, GridOn, InsertDriveFile } from '@mui/icons-material';
import TableActionButton from '../atoms/TableActionButton';

const ExportTableDataMenu = () => {
  const [exportEl, setExportEl] = useState<HTMLElement | null>(null);
  const openExport = Boolean(exportEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportEl(event.currentTarget as HTMLElement);
  };

  const handleClose = () => {
    setExportEl(null);
  };

  const menuItems = [
    { title: 'Save as PDF', icon: PictureAsPdf, clickHandler: handleClose },
    { title: 'Save as Excel', icon: GridOn, clickHandler: handleClose },
    { title: 'Save as CSV', icon: InsertDriveFile, clickHandler: handleClose },
  ];

  return (
    <>
      <TableActionButton onClick={handleClick} Icon={GetApp}>
        Export
      </TableActionButton>

      <Menu id='export-menu' anchorEl={exportEl} open={openExport} onClose={handleClose}>
        {menuItems.map((item) => (
          <MenuItem key={item.title} onClick={item.clickHandler}>
            <ListItemIcon>
              <item.icon fontSize='small' />
            </ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ExportTableDataMenu;
