import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { NavigationTabOption, StyleObj } from '../../@types';
import usePermissions from '../../hooks/usePermissions';

type TabNavigationProps = {
  tabs: NavigationTabOption[];
  icon?: ReactElement;
  basePath?: string;
};

const styles: StyleObj = {
  container: {
    width: '100%',
    minWidth: 0,
    backgroundColor: 'background.light',
    borderBottom: 1,
    borderColor: 'divider',
  },
  tabs: {
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      width: '100%',
    },
  },
  defaultTabIndicator: {
    display: 'none',
  },
};

const makeTabStyle = (active: boolean, numberOfTabs: number) => {
  return {
    tab: {
      minWidth: 0,
      textTransform: 'none',
      minHeight: '50px',
      fontWeight: 600,
      width: `calc(100% / ${numberOfTabs})`,
      borderBottom: active ? '2px solid' : '2px solid transparent',
      transition: 'all 0.2s ease-in-out',
      '&.Mui-selected': {
        borderBottomColor: 'primary.main',
      },
    },
  };
};

const TabNavigation = ({ tabs, icon, basePath }: TabNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { filterAccessibleTabs } = usePermissions();

  const validTabValues = tabs.map((tab) => tab.value);

  // Handle nested tab navigation with basePath prop so we can get the correct tab value for parent navigation
  const pathSegment = basePath
    ? location.pathname.substring(location.pathname.indexOf(basePath) + basePath.length + 1).split('/')[0]
    : location.pathname.split('/').pop();

  const [currentTab, setCurrentTab] = useState<string | false>(false);

  useEffect(() => {
    if (pathSegment && validTabValues.includes(pathSegment)) {
      setCurrentTab(pathSegment);
    } else {
      setCurrentTab(false);
    }
  }, [validTabValues, pathSegment]);

  const handleTabChange = (_: React.SyntheticEvent, selectedTabValue: string) => {
    setCurrentTab(selectedTabValue);
    navigate(`./${selectedTabValue}`);
  };

  const accessibleTabs = filterAccessibleTabs(tabs);

  if (!currentTab) return <Outlet />;

  return (
    <>
      <Box sx={styles.container}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          textColor='inherit'
          sx={styles.tabs}
          TabIndicatorProps={{
            sx: styles.defaultTabIndicator,
          }}
        >
          {accessibleTabs.map(({ label, value }) => (
            <Tab
              icon={icon}
              iconPosition='start'
              key={value}
              label={label}
              value={value}
              sx={makeTabStyle(currentTab === value, tabs.length).tab}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </>
  );
};

export default TabNavigation;
